var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ad-guard-filter-status-wrapper" }, [
    _vm.status !== null && _vm.showOnOffStatusOnly
      ? _c("div", { staticClass: "status" }, [
          _c("span", { staticClass: "status-lbl" }, [
            _vm._v(_vm._s(_vm.$t("widgets.pi-hole.status-heading")) + ":"),
          ]),
          _c(
            "span",
            { class: `status-val ${_vm.getStatusColor(_vm.status)}` },
            [_vm._v(" " + _vm._s(_vm.status ? "Enabled" : "Disabled") + " ")]
          ),
        ])
      : _vm._e(),
    _vm.filters && !_vm.showOnOffStatusOnly
      ? _c(
          "div",
          { staticClass: "filters-list" },
          _vm._l(_vm.filters, function (filter) {
            return _c("div", { key: filter.id, staticClass: "filter" }, [
              _c("div", { staticClass: "row-1" }, [
                _c(
                  "span",
                  { class: `on-off ${filter.enabled ? "green" : "red"}` },
                  [_vm._v(" " + _vm._s(filter.enabled ? "✔" : "✘") + " ")]
                ),
                _c("span", { staticClass: "filter-name" }, [
                  _vm._v(_vm._s(filter.name)),
                ]),
                _c("span", { staticClass: "filter-rules-count" }, [
                  _vm._v(_vm._s(filter.rules_count)),
                ]),
              ]),
              _c("div", { staticClass: "row-2" }, [
                _c("span", { staticClass: "updated" }, [
                  _vm._v(
                    "Updated " +
                      _vm._s(_vm._f("formatDate")(filter.last_updated))
                  ),
                ]),
                filter.url
                  ? _c(
                      "a",
                      {
                        staticClass: "filter-link",
                        attrs: { href: filter.url },
                      },
                      [_vm._v("View List")]
                    )
                  : _vm._e(),
              ]),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }